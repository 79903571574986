import React, { Component } from 'react';
import qs from 'query-string';

export default class Download extends Component {
  componentWillMount() {
    detectAndServe(qs.parse(this.props.location.search).store);
  }

  render() {
    return (
      <div className="wrapper">
        <div className="section">
          <div className="container">
            <div className="row">
              <h3 className="col-md-10 col-md-offset-1" style={{ textAlign: 'center', marginTop: '150px' }}>
                LOADING...
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const getOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // detect if mobile device
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ) {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return "desktop";
};

const detectAndServe = (store = null) => {
  const os = getOperatingSystem(store);

  if (os === 'desktop' || os === 'unknown' || os === "Windows Phone") {
    if (store === 'android') {
      window.location.href = "https://play.google.com/store/apps/details?id=com.rapidstax.nursingroomsg";
    } else {
      window.location.href = "https://itunes.apple.com/sg/app/nursing-room-singapore/id1378657301?mt=8";
    }
  } else {
    if (os === "Android") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.rapidstax.nursingroomsg";
    } else {
      window.location.href = "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1378657301&mt=";
    }
  }
};