import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AppStoreBadge from '../../assets/svg/app-store-badge-white.svg';
import PlayStoreBadge from '../../assets/img/google-play-badge.png';

export default class Hero extends Component {
  render() {
    return (
      <div className="parallax filter-gradient red" data-color="red">
        <div className="parallax-background">
          <img className="parallax-background-image" src="assets/img/showcases/showcase-1/bg7.jpg" alt=""/>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="description">
                <h2>Find the nearest Nursing Room in Singapore.</h2>
                <br/>
                <h5>Being a new parent is tough. Finding a nearby nursing room is even harder!
                  Download the nursing room app on your mobile now and leave the quest for a nursing room to the app!</h5>
              </div>
              <div className="buttons">
                <Link to="/download?store=apple" target="_blank">
                  <img src={AppStoreBadge} alt=""/>
                </Link>
                <Link to="/download?store=android" target="_blank">
                  <img src={PlayStoreBadge} alt="" style={{ width: 150 + 'px' }}/>
                </Link>
              </div>
            </div>
            <div className="col-md-5  hidden-xs">
              <div className="parallax-image">
                <img className="phone" src="assets/img/showcases/showcase-1/iphone-uss.png" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}