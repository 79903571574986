import React, { Component } from 'react';

const mediaIcon = {
  fontSize: 32 + 'px',
  fontFamily: "Helvetica Neue, Open Sans, Arial, sans-serif",
  fontWeight: 200,
  color: 'grey',
  margin: '0 25px',
};

export default class Map extends Component {
  render() {
    return (
      <div className="section section-gray section-clients">
        <div className="container text-center">
          <h4 className="header-text">Great app for moms and dads</h4>
          <p>
            Moms need a good, comfortable place to nurse her newborn. Dads need a quick and easy app to find the nearest diaper
            changing room. With the Nursing Room app, gone are the days where you have to "google" your way to the nearest
            nursing room or diaper changing room! Simply load the app, and it'll instantly show you the nearest nursing rooms where
            ever you are!<br/>
          </p>
          <div className="logos">
            <ul className="list-unstyled">
              <li style={mediaIcon}><i className="pe-7s-map-marker"/> locate</li>
              <li style={mediaIcon}><i className="pe-7s-paper-plane"/> search</li>
              <li style={mediaIcon}><i className="pe-7s-like"/> favorite</li>
              <li style={mediaIcon}><i className="pe-7s-way"/> direct</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}