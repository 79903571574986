import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "../ScrollToTop";

import Nav from "./Nav";
import Hero from "./Hero";
import Map from "./Map";
import Presentation from "./Presentation";
import Demo from "./Demo";
import Features from "./Features";
import Testimonials from "./Testimonials";
import Download from "./Download";
import Footer from "./Footer";

import DownloadPage from "../download";
import Privacy from "../privacy";
import NotFound from "../NotFound";

const AppRouter = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route path="/" exact component={App}/>
        <Route path="/download" component={DownloadPage}/>
        <Route path="/privacy" component={Privacy}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    </ScrollToTop>
  </Router>
);

export default AppRouter;

class App extends Component {
  render() {
    return (
      <Fragment>
        <Nav/>
        <div className="wrapper">
          <Hero/>
          <Map/>
          <Presentation/>
          <Demo/>
          <Features/>
          <Testimonials/>
          <Download/>
          <Footer/>
        </div>
      </Fragment>
    );
  }
}