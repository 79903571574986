import React, { Component } from 'react';

export default class Features extends Component {
  render() {
    return (
      <div className="section section-features">
        <div className="container">
          <h4 className="header-text text-center">Features</h4>
          <div className="row">
            <div className="col-md-4">
              <div className="card card-orange">
                <div className="icon">
                  <i className="pe-7s-map-marker"/>
                </div>
                <div className="text">
                  <h4>Search by location</h4>
                  <p>Search for any nursing room by typing the name of a mall name, address, location or let it show you the
                    nearest five.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-orange">
                <div className="icon">
                  <i className="pe-7s-like"/>
                </div>
                <h4>Favourite a nursing room</h4>
                <p>Plan your outing by placing the nearby nursing rooms to your Favourites list. Never leave the house unprepared
                  again!</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-orange">
                <div className="icon">
                  <i className="pe-7s-way"/>
                </div>
                <h4>Get exact directions</h4>
                <p>Let the app show you the way to the selected nursing room. You need to get there ASAP. Let us show you the
                  way.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}