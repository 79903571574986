import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class Nav extends Component {
  render() {
    return (
      <nav className="navbar navbar-transparent navbar-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button id="menu-toggle" type="button" className="navbar-toggle" data-toggle="collapse" data-target="#example">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar bar1"/>
              <span className="icon-bar bar2"/>
              <span className="icon-bar bar3"/>
            </button>
            <Link to="/">
              <div className="logo-container">
                <div className="logo">
                  <img src="assets/img/nursingroom-logo-120x120.png" alt="Nursing Room Singapore"/>
                </div>
                <div className="brand">
                  Nursing Room Singapore
                </div>
              </div>
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="example">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/download" target="_blank">
                  <i className="fa fa-cloud-download"/> Download
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  <i className="fa fa-info-circle"/> Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}