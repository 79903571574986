import React, { Component } from 'react';

export default class Testimonials extends Component {
  render() {
    return (
      <div className="section section-testimonial">
        <div className="container">
          <h4 className="header-text text-center">What people think</h4>
          <div id="carousel-example-generic" className="carousel fade" data-ride="carousel">
            <div className="carousel-inner" role="listbox">
              <div className="item">
                <div className="mask">
                  <img src="assets/img/faces/face-man.jpg" alt=""/>
                </div>
                <div className="carousel-testimonial-caption">
                  <p>Patrick</p>
                  <h3>"You should allow users to submit and review the nursing rooms too!"</h3>
                </div>
              </div>
              <div className="item active">
                <div className="mask">
                  <img src="assets/img/faces/face-woman.jpg" alt=""/>
                </div>
                <div className="carousel-testimonial-caption">
                  <p>Rebecca</p>
                  <h3>"Went to Northpoint Shopping Centre at Yishun. The nursing rooms there are great."</h3>
                </div>
              </div>
              <div className="item">
                <div className="mask">
                  <img src="assets/img/faces/face-woman.jpg" alt=""/>
                </div>
                <div className="carousel-testimonial-caption">
                  <p>Jayda</p>
                  <h3>"I absolutely love your app! It's truly amazing and looks awesome!"</h3>
                </div>
              </div>
            </div>
            <ol className="carousel-indicators carousel-indicators-orange">
              <li data-target="#carousel-example-generic" data-slide-to="0"/>
              <li data-target="#carousel-example-generic" data-slide-to="1" className="active"/>
              <li data-target="#carousel-example-generic" data-slide-to="2"/>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}