import React, { Component } from 'react';

export default class Presentation extends Component {
  render() {
    return (
      <div className="section section-presentation">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="description">
                <h4 className="header-text">By Dad, for Dads</h4>
                <p>And Moms too. We designed the app to be as easy and straight-forward to use as possible. As a new Dad, we were
                  all in the same situation before. The son just pooped and we are looking for a nearby nursing room. "Where's
                  that darn room!"</p>
                <p>That is where this app shines. Now Dads and Moms just simply load the app, and instantly be told on which floor
                  is the nearest nursing room; if you are in a mall, the cleanliness; if that is important for you, and the
                  amenities to expect. There you go, the day is saved!</p>
              </div>
            </div>
            <div className="col-md-5 col-md-offset-1 hidden-xs">
              <img src="assets/img/showcases/showcase-1/iphones-2.png" style={{ top: "-50px" }} alt=""/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}