import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <div className="wrapper">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <h2>Not Found</h2>
                <p>The page you are looking for does not exist.</p>
                <br/>
                <div>
                  <Link to="/">
                    <button className="btn btn-fill btn-facebook">Back to home</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}