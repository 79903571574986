import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <nav className="pull-left">
            <ul>
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/download">
                  Download
                </Link>
              </li>
              <li>
                <Link to="/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
          {/*<div className="social-area pull-right copyright">
            <a className="btn btn-social btn-facebook btn-simple">
              <i className="fa fa-facebook-square"/>
            </a>
            <a className="btn btn-social btn-twitter btn-simple">
              <i className="fa fa-twitter"/>
            </a>
            <a className="btn btn-social btn-pinterest btn-simple">
              <i className="fa fa-pinterest"/>
            </a>
          </div>*/}
          <div className="pull-right copyright">
            &copy; 2018 <Link to="/">Nursing Room Singapore</Link>
          </div>
        </div>
      </footer>
    );
  }
}