import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Download extends Component {
  render() {
    return (
      <div className="section section-no-padding">
        <div className="parallax filter-gradient red" data-color="red">
          <div className="parallax-background">
            <img className="parallax-background-image flipped" src="assets/img/showcases/showcase-1/bg6.jpg" alt=""/>
          </div>
          <div className="info">
            <h1>Download this app for free!</h1>
            <p>Nearest nursing room when you need it.</p>
            <Link to="/download" className="btn btn-neutral btn-lg btn-fill" target="_blank">DOWNLOAD</Link>
          </div>
        </div>
      </div>
    );
  }
}