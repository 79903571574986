import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AppStoreBadge from "../../assets/svg/app-store-badge.svg";
import PlayStoreBadge from "../../assets/img/google-play-badge.png";

export default class Demo extends Component {
  render() {
    return (
      <div className="section section-demo">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div id="description-carousel" className="carousel fade" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="item">
                    <img src="assets/img/showcases/showcase-1/examples/home_3a.jpg" alt=""/>
                  </div>
                  <div className="item active">
                    <img src="assets/img/showcases/showcase-1/examples/home_1a.jpg" alt=""/>
                  </div>
                </div>
                <ol className="carousel-indicators carousel-indicators-orange">
                  <li data-target="#description-carousel" data-slide-to="0" className=""/>
                  <li data-target="#description-carousel" data-slide-to="1" className="active"/>
                </ol>
              </div>
            </div>
            <div className="col-md-5 col-md-offset-1">
              <h4 className="header-text">Easy to use</h4>
              <p>
                Download, load, allow the app to use your location, and instantly know where the nearest nursing rooms are located!
                Know what amenities are available in each nursing room!
                <br/>
                <br/>
              </p>
              <Link to="/download?store=apple" target="_blank"><img src={AppStoreBadge} alt="Apple App Store"/></Link>
              <Link to="/download?store=android" target="_blank"><img src={PlayStoreBadge} style={{ width: 150 + 'px' }} alt="Google Play Store"/></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}